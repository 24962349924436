import { Component, Input, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'app-client-image-text',
  templateUrl: './client-image-text.component.html',
  styleUrls: ['./client-image-text.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ClientImageTextComponent {
    @Input() data: any;
}
