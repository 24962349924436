import { Component, OnInit } from '@angular/core';
import { Apollo } from 'apollo-angular';

import { PORTFOLIO_SECTION_QUERY, PortfolioSectionData, PortfolioData } from 'src/app/graphql';

@Component({
    selector: 'app-portfolio-section',
    templateUrl: './portfolio-section.component.html',
    styleUrls: ['./portfolio-section.component.scss']
})
export class PortfolioSectionComponent implements OnInit {
    data: PortfolioSectionData;

    constructor(private apollo: Apollo) {}

    ngOnInit(): void {
        this.apollo.watchQuery<PortfolioSectionData>({
            query: PORTFOLIO_SECTION_QUERY
        }).valueChanges.subscribe((response) => {
            this.data = response.data;
        });
    }
}
