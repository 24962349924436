import { Component, DoCheck, EventEmitter, HostListener, OnChanges, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { debounce } from 'lodash';
@Component({
    selector: 'app-page-header',
    templateUrl: './page-header.component.html',
    styleUrls: ['./page-header.component.scss']
})
export class PageHeaderComponent implements OnInit, DoCheck {
    open = false;
    mobile: boolean;
    scrolledToTop: boolean;
    scrolledToBottom: boolean;
    circle = false;
    clientHeight = document.body.clientHeight;
    @Output() openContactFormModal = new EventEmitter();
    private debouncedResize = debounce(() => this.onWindowResize(), 50);
    private debouncedScroll = debounce(() => this.onWindowScroll(), 17);

    constructor( private router: Router) {}

    // temporary, will be removed when BE ready
    links = [{ name: 'Work', url: '/work' }, { name: 'Services', url: '/services' }];

    ngOnInit(): void {
        this.onWindowResize();
        this.onWindowScroll();
    }

    ngDoCheck(): void {
        if (this.router.url !== '/' && this.router.url !== '/services') {
            this.circle = true;
        }
        else {
            this.circle = false;
        }
    }

    @HostListener('window:resize')
    onResize(): void {
        this.debouncedResize();
    }

    private onWindowResize(): void {
        this.mobile = window.innerWidth < 991;
    }

    @HostListener('window:scroll')
    onScroll(event: any): void {
        this.debouncedScroll();
    }

    private onWindowScroll(): void {
        this.scrolledToTop = window.scrollY === 0;
        if (document.body.scrollHeight > this.clientHeight) {
            if (this.router.url !== '/') {
                if (!this.scrolledToTop) {
                    this.scrolledToBottom = true;
                }
                else {
                    this.scrolledToBottom = false;
                }
            }
            else {
                this.scrolledToBottom = window.innerHeight + window.scrollY >= document.body.offsetHeight - 200;
            }
        } else {
            this.scrolledToBottom = false;
        }
    }

    toggleMenu(): void {
        this.open = !this.open;
        if (this.open) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
    }

    openFormModal(): void {
        this.openContactFormModal.emit();
    }
}
