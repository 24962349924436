import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-hamburger',
    templateUrl: './hamburger.component.html',
    styleUrls: ['./hamburger.component.scss']
})
export class HamburgerComponent {
    @Input() open: boolean;
}
