import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-client-details',
  templateUrl: './client-details.component.html',
  styleUrls: ['./client-details.component.scss'],
})
export class ClientDetailsComponent implements OnInit {
  @Input() heading: string;
  @Input() text: string;
  @Input() services: { name: string; image: { url: string } }[];
  @Input() integrations: { logo: { url: string } }[];
  @Input() liveSiteUrl: string;

  constructor() {}

  ngOnInit(): void {}
}
