<div *ngIf="data" class="image-text" >
    <div class="direction" [class]="data.imageContainerWidth" [ngClass]="data.imageOnRight? 'reversed' : 'no-reversed'">
        <div class="image">
            <img [src]="data.image.url">
        </div>
        <div class="text">
            <div>
                <h1>{{data.title}}</h1>
                <p class="description font-secondary" [innerHtml]="data.description.html"></p>
            </div>
        </div>
    </div>
</div>