import { Component, OnInit, ViewChild } from '@angular/core';
import { Apollo } from 'apollo-angular';
import SwiperCore, { EffectCoverflow } from 'swiper';

import { CLIENTS_CAROUSEL_QUERY, CarouselData, CarouselClientData } from 'src/app/graphql';

SwiperCore.use([EffectCoverflow]);

@Component({
    selector: 'app-carousel',
    templateUrl: './carousel.component.html',
    styleUrls: ['./carousel.component.scss']
})
export class CarouselComponent implements OnInit {
    data: CarouselClientData[];
    @ViewChild('carousel') swiper: any;

    constructor(private apollo: Apollo) { }

    ngOnInit(): void {
        this.apollo.watchQuery<CarouselData>({
            query: CLIENTS_CAROUSEL_QUERY
        }).valueChanges.subscribe((response) => {
            this.data = response.data.clients;
        });
    }

    nextSlide(): void{
       this.swiper.swiperRef.slideNext();
    }
}
