import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-client-hero',
  templateUrl: './client-hero.component.html',
  styleUrls: ['./client-hero.component.scss'],
})
export class ClientHeroComponent implements OnInit {
  @Input() name: string;
  @Input() image: string;
  @Input() title: string;

  constructor() {}

  ngOnInit(): void {}
}
