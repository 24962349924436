import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, ViewChild } from '@angular/core';

@Component({
    selector: 'app-highlight',
    templateUrl: './highlight.component.html',
    styleUrls: ['./highlight.component.scss'],
})
export class HighlightComponent implements AfterViewInit {

    @Input() svgPath: string;

    widthWord: any;
    @ViewChild('highlightWord')
    highlightWord: ElementRef;

    constructor(protected changeDetectorRef: ChangeDetectorRef) { }

    private detectChanges(): void {
        this.changeDetectorRef.detectChanges();
    }

    ngAfterViewInit(): void {
        this.widthWord = this.highlightWord.nativeElement.offsetWidth + 0.08 * this.highlightWord.nativeElement.offsetWidth;
        this.detectChanges();
    }
}
