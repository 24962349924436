<div class="form-modal bg-col-2">

    <div class="modal-wrap">
        <img class="logo"
             src="/assets/logo.svg"
             alt="logo">

        <div class="flex-container">

            <!-- form -->
            <div *ngIf="!success && !error; else info"
                 class="form">
                <h3 class="form-heading">Do you have a project in mind? Drop us a line.</h3>
                <button class="close-btn"
                        (click)="closeModal()"></button>
                <form #f="ngForm"
                      (ngSubmit)="onSubmit(f)">
                    <fieldset>
                        <div class="form-group">
                            <input type="text"
                                   name="name"
                                   required
                                   ngModel
                                   #name="ngModel"
                                   [class.not-empty]="name.value">
                            <label for="name">Full name</label>
                            <span *ngIf="name.invalid  && (name.dirty || name.touched)"
                                  class="error-msg">Please enter your name</span>
                        </div>
                        <div class="form-group">
                            <input type="text"
                                   name="company"
                                   ngModel
                                   #company="ngModel"
                                   [class.not-empty]="company.value">
                            <label for="company">Company</label>
                        </div>
                        <div class="form-group">
                            <input type="email"
                                   name="email"
                                   required
                                   email
                                   ngModel
                                   #email="ngModel"
                                   [class.not-empty]="email.value"
                                   [ngModelOptions]="{ updateOn: 'blur' }">
                            <label for="email">Email</label>
                            <span *ngIf="email.invalid  && (email.dirty || email.touched)"
                                  class="error-msg">Please enter a valid email</span>
                        </div>
                        <div class="form-group">
                            <input type="tel"
                                   name="telephone"
                                   ngModel
                                   #telephone="ngModel"
                                   [class.not-empty]="telephone.value">
                            <label for="telephone">Phone number</label>
                        </div>
                        <div class="form-group">
                            <textarea name="message"
                                      required
                                      ngModel
                                      #message="ngModel"
                                      [class.not-empty]="message.value"></textarea>
                            <label for="message">Message</label>
                            <span *ngIf="message.invalid  && (message.dirty || message.touched)"
                                  class="error-msg">Please enter a message</span>
                        </div>
                        <div class="form-group form-cta">
                            <button class="cta"
                                    [class.busy]="loading"
                                    [disabled]="!f.valid"
                                    type="submit">
                                <span>
                                    {{loading ? 'Please wait' : 'Send message'}}
                                </span>
                            </button>
                        </div>
                    </fieldset>
                </form>
            </div>

            <!-- contact -->
            <section class="contact-section">
                <div class="contact-group">
                    <h5 class="field-name">Email</h5>
                    <a #infoEmail class="field-content eml-protected"
                       href="#">9cf5f2faf3dce8f3f1fdf2f8fff3b2fff3b2e9f7</a>
                </div>
                <div class="contact-group">
                    <h5 class="field-name">Jobs</h5>
                    <a #jobEmail class="field-content eml-protected"
                       href="#">9cf6f3feefdce8f3f1fdf2f8fff3b2fff3b2e9f7</a>
                </div>
                <div class="contact-group">
                    <h5 class="field-name">Telephone</h5>
                    <a class="field-content"
                       href="tel:327220601">+32 722 06 01</a>
                </div>
                <div class="contact-group">
                    <h5 class="field-name">Address</h5>
                    <address class="field-content">
                        św. Jana 20<br>
                        40-012 Katowice
                    </address>
                </div>
            </section>
        </div>
    </div>
    <object class="decor" data="/assets/images/svg/decor.svg" type="image/svg+xml"></object>
</div>

<!-- message -->
<ng-template #info>
    <div class="message font-primary">
        {{success ? data?.successMessage : data?.errorMessage}}
    </div>
</ng-template>
