<div class="section portfolios__section">
    <div *ngIf="data"
         class="portfolios__section__container">
        <a *ngFor="let client of data.clients;index as i"
           class="portfolio__container"
           [routerLink]="'work/'+client.slug"
           [ngClass]="{'lower': i==1}">
            <img src="{{client.image.url}}"
                 alt="image">
            <div>
                <img class="portfolio__container__logo"
                     src="{{client.logo.url}}"
                     alt="logo">
                <button class="portfolio__container__button">View project</button>
            </div>
        </a>
    </div>
</div>
