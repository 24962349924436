import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { PageHeaderComponent } from './page-header/page-header.component';
import { HamburgerComponent } from './page-header/hamburger/hamburger.component';
import { PageHomeComponent } from './page-home/page-home.component';
import { PageWorkComponent } from './page-work/page-work.component';
import { PageServicesComponent } from './page-services/page-services.component';
import { FeatureSectionComponent } from './page-home/feature-section/feature-section.component';
import { ContactFormModalComponent } from './contact-form-modal/contact-form-modal.component';
import { FormsModule } from '@angular/forms';
import { SectionComponent } from './page-home/section/section.component';
import { HeroComponent } from './page-home/hero/hero.component';
import { LogosSectionComponent } from './page-home/logos-section/logos-section.component';
import { HighlightComponent } from './common/highlight/highlight.component';
import { ServicesComponent } from './page-services/services-component/services-component.component';
import { GraphQLModule } from './graphql.module';
import { HttpClientModule } from '@angular/common/http';
import { PageClientComponent } from './page-client/page-client.component';
import { PortfolioSectionComponent } from './page-home/portfolio-section/portfolio-section.component';
import { ClientHeroComponent } from './page-client/client-hero/client-hero.component';
import { ClientDetailsComponent } from './page-client/client-details/client-details.component';
import { ClientStatisticComponent } from './page-client/client-statistic/client-statistic.component';
import { CarouselComponent } from './common/carousel/carousel.component';
import { SwiperModule } from 'swiper/angular';
import { ClientImageTextComponent } from './page-client/client-image-text/client-image-text.component';
import { PageFooterComponent } from './page-footer/page-footer.component';
import { PageErrorComponent } from './page-error/page-error.component';
import { AwardsSectionComponent } from './page-home/awards-section/awards-section.component';
import { PerksSectionComponent } from './page-home/perks-section/perks-section.component';
import { IntroSectionComponent } from './page-home/intro-section/intro-section.component';

@NgModule({
    bootstrap: [AppComponent],
    declarations: [
        AppComponent,
        PageHeaderComponent,
        HamburgerComponent,
        PageHomeComponent,
        PageWorkComponent,
        PageServicesComponent,
        ContactFormModalComponent,
        SectionComponent,
        HeroComponent,
        LogosSectionComponent,
        PageClientComponent,
        HighlightComponent,
        ClientHeroComponent,
        ClientDetailsComponent,
        CarouselComponent,
        PortfolioSectionComponent,
        ServicesComponent,
        ClientDetailsComponent,
        ClientStatisticComponent,
        ClientImageTextComponent,
        FeatureSectionComponent,
        ClientStatisticComponent,
        PageFooterComponent,
        PageErrorComponent,
        AwardsSectionComponent,
        PerksSectionComponent,
        IntroSectionComponent,
    ],
    imports: [
        BrowserModule.withServerTransition({ appId: 'serverApp' }),
        FormsModule,
        AppRoutingModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.production,
        }),
        GraphQLModule,
        HttpClientModule,
        SwiperModule,
    ],
    providers: [],
})
export class AppModule {}
