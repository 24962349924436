import { Component } from '@angular/core';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    contactModalIsOpen = false;

    handleContactModal(value: boolean): void{
        this.contactModalIsOpen = value;
        document.body.style.overflow = value ? 'hidden' : 'auto';
    }

}
