<div class="perk-section"
     *ngIf="data?.perksSection">
    <h1 class="perk-section__title">{{data?.perksSection?.title}}</h1>
    <div class="perk-section__grid"
         *ngIf="data">
        <div *ngFor="let perk of data?.perksSection?.perks"
             class="perk-item">
            <div class="perk-item__image"
                 [ngStyle]="{'background-image': 'url(' + perk.image.url + ')'}"></div>
            <span class="separation-line">
                <hr class="separation-line__container">
            </span>
            <h3 class="perk-item__title">{{perk.title}}</h3>
            <p class="perk-item__copy">{{perk.copy}}</p>
        </div>
    </div>
</div>