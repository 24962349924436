<div class="services-component" *ngIf="data">
    <h1>
        {{data.servicesHeaders[0].beforeHighlightText}}
            <app-highlight *ngIf="data.servicesHeaders[0].highlightText"
                class="word font-primary"
                svgPath="/assets/images/svg/highlight.svg">{{data.servicesHeaders[0].highlightText}}</app-highlight>
        {{data.servicesHeaders[0].afterHighlightText}}
    </h1>
    <div class="arrow col-1">
        <h3>{{data.servicesHeaders[0].arrowTitle}}</h3>
        <img src="/assets/images/svg/arrow-5.svg">
    </div>
    <div class="services">
        <ng-container *ngFor="let service of data.services; let i = index">
            <div class="one-service" [ngClass]="(i!=0 && i!=1 && i!=3 && i!=5 && i!=6 && i!=7)?'service':'service-' + i">
                <img [src]="service.image.url" alt="">
                <h4>{{service.name}}</h4>
                <p class="font-secondary">{{service.description}}</p>
            </div>
        </ng-container>
    </div>
</div>
