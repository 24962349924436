import { Component, Input} from '@angular/core';

@Component({
  selector: 'app-client-statistic',
  templateUrl: './client-statistic.component.html',
  styleUrls: ['./client-statistic.component.scss']
})
export class ClientStatisticComponent{
    @Input() dataText: any;
}
