<section class="wrap client-details">
  <div class="content">
    <h2 class="title">{{ heading }}</h2>
    <div class="content-right">
      <p class="text">{{ text }}</p>
      <a [href]="liveSiteUrl" class="col-1 link">View the site</a>
    </div>
  </div>

  <div class="icons">
    <!-- services -->
    <div class="features-container">
      <h4 class="features-heading font-secondary">Services we provide</h4>
      <div class="features">
        <div *ngFor="let service of services" class="service">
          <img class="service-logo" [src]="service.image.url" alt="logo" />
          <span class="service-name">{{ service.name }}</span>
        </div>
      </div>
    </div>

    <!-- integrations -->
    <div class="features-container">
      <h4 class="features-heading font-secondary">Integrations</h4>
      <div class="features">
        <div *ngFor="let item of integrations" class="integration">
          <img [src]="item.logo.url" alt="logo" class="integration-logo" />
        </div>
      </div>
    </div>
  </div>
</section>
