import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageClientComponent } from './page-client/page-client.component';
import { PageErrorComponent } from './page-error/page-error.component';
import { PageHomeComponent } from './page-home/page-home.component';
import { PageServicesComponent } from './page-services/page-services.component';
import { PageWorkComponent } from './page-work/page-work.component';

const routes: Routes = [
    { path: '', component: PageHomeComponent },
    { path: 'work', component: PageWorkComponent },
    { path: 'work/:id', component: PageClientComponent },
    { path: 'services', component: PageServicesComponent },
    { path: '404', component: PageErrorComponent },
    { path: '**', redirectTo: '/404' },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
