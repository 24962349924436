<div class="awards-section"
     *ngIf="data?.awardsSection">
    <div class="awards-section__image">
        <img [src]="data?.awardsSection?.image?.url">
    </div>
    <div class="awards-section__data">
        <h1 class="awards-section__title">{{data?.awardsSection?.title}}</h1>
        <p class="awards-section__copy">{{data?.awardsSection?.copy}}</p>
        <div class="awards-items">
            <div class="awards-item"
                 [style.margin-top.rem]="!isFirst && !isMobileTablet ? 1.25 : ''"
                 *ngFor="let award of data?.awardsSection?.awards; let isFirst = first">
                <div class="awards-item__image">
                    <img src="{{award.image.url}}">
                </div>
                <div class="awards-item__data">
                    <p class="awards-item__title">{{award.title}}</p>
                    <p class="awards-item__year"
                       *ngIf="isMobileTablet">{{award.year}}</p>
                    <div class="awards-item__copy"
                         [innerHtml]="award.copy.html"
                         *ngIf="!isMobileTablet"></div>
                </div>
            </div>
        </div>
    </div>
</div>