import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-hero',
    templateUrl: './hero.component.html',
    styleUrls: ['./hero.component.scss'],
})
export class HeroComponent {
    @Input() beforeHighlightText: string;
    @Input() highlightText: string;
    @Input() afterHighlightText: string;
    @Input() banerDescription: string;
}
