<div class="header-wrap"
     [class.promptContainer]="scrolledToBottom">
    <header class="header"
            [class.decor]="mobile && open"
            [class.promptContainer]="scrolledToBottom">
        <a routerLink="/">
            <img class="logo"
                 src="/assets/logo.svg"
                 alt="logo"
                 [class.hidden]="!scrolledToTop && (!open || !mobile)">
        </a>
        <div class="hamburger-container">
            <app-hamburger *ngIf="!scrolledToBottom"
                           (click)="toggleMenu()"
                           [open]="open"></app-hamburger>
        </div>
        <!-- nav -->
        <nav [class.closed]="mobile && !open"
             [class.bg-col-4]="mobile"
             class="nav">
            <ul class="nav-list">
                <li *ngFor="let link of links"
                    [class.hidden]="!scrolledToTop">
                    <a [routerLink]="link.url"
                       class="nav-link"
                       (click)="mobile? toggleMenu() : ''"
                       [ngClass]="link.name.toLowerCase() + (mobile ? ' font-primary' : ' font-secondary')">{{link.name}}</a>
                </li>
                <li>
                    <button class="cta nav-cta"
                            (click)="openFormModal()"
                            [class.active]="!scrolledToTop && !mobile">Let's talk</button>
                </li>
            </ul>
        </nav>

        <!-- prompt -->
        <div *ngIf="scrolledToBottom"
             class="prompt font-primary">
            <span class="prompt-text">
                Do you want to tell us about your project?
            </span>
            <button *ngIf="mobile"
                    (click)="openFormModal()"
                    class="cta nav-cta active font-secondary">Let's talk</button>
        </div>
    </header>
</div>

<span [ngClass]="circle? 'circle':''"></span>