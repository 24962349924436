import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Apollo } from 'apollo-angular';
import { CLIENT_DATA_QUERY, ClientData } from '../graphql';


@Component({
    selector: 'app-page-client',
    templateUrl: './page-client.component.html',
    styleUrls: ['./page-client.component.scss']
})
export class PageClientComponent implements OnInit {
    pageData: ClientData;

    constructor(private route: ActivatedRoute, private router: Router, private apollo: Apollo) { }

    ngOnInit(): void {
        const slug = this.route.snapshot.params.id;
        this.apollo.watchQuery<ClientData>({
            query: CLIENT_DATA_QUERY, variables: { slug }
        }).valueChanges.subscribe((response) => {
            if (response.data.client) {
                this.pageData = response.data;
            } else {
                // change to 404 page when added
                this.router.navigate(['/']);
            }
        });
    }
}
