<footer class="footer" *ngIf="data">
    <p class="info">
        <span>{{data.label}}</span>
        <a [href]="'tel:'+data.phoneNumber"
           class="footer-phone">{{data.phoneNumberToDisplay}}</a>
        <a (click)="openFormModal()"
           class="meet-link">{{data.buttonText}}</a>
    </p>
    <img src="/assets/images/magento_business.png"
         alt="">
</footer>
