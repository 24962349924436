import { Component, OnInit } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { SERVICES_HEADER_QUERY, ServicesData, ServiceHeaderData} from 'src/app/graphql';
@Component({
  selector: 'app-services-component',
  templateUrl: './services-component.component.html',
  styleUrls: ['./services-component.component.scss'],
})
export class ServicesComponent implements OnInit {
    data: ServicesData;

    constructor(private apollo: Apollo) {}

    ngOnInit(): void {
        this.apollo.watchQuery<ServicesData>({
            query: SERVICES_HEADER_QUERY
        }).valueChanges.subscribe((response) => {
            this.data = response.data;
        });
    }
}
