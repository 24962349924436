<ng-container *ngIf="pageData?.client">
    <app-client-hero [image]="pageData.client.image.url"
                     [title]="pageData.client.heroBannerTitle"
                     [name]="pageData.client.name"></app-client-hero>
    <app-client-details [heading]="pageData.client.infoHeading"
                        [text]="pageData.client.infoText"
                        [integrations]="pageData.client.integrations"
                        [services]="pageData.client.services"
                        [liveSiteUrl]="pageData.client.liveSiteUrl"></app-client-details>
    <ng-container *ngFor="let component of pageData.client.components">
        <app-client-statistic *ngIf="component.__typename === 'Statistic'"
                              [dataText]="component"></app-client-statistic>
        <app-client-image-text *ngIf="component.__typename === 'ImageText'" [data]="component"></app-client-image-text>
    </ng-container>
</ng-container>
