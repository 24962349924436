<section class="slider">
    <button class="slider-nav"
            aria-label="next-slide"
            (click)="nextSlide()"></button>
    <swiper *ngIf="data"
            [centeredSlides]="true"
            [loop]="true"
            [breakpoints]="{
                '320': {
                    slidesPerView: 1
                },
                '768': {
                    slidesPerView: 2
                },
                '991': {
                    slidesPerView: 3
                },
                '1440': {
                    slidesPerView: 3,
                    spaceBetween: 50
                }
            }"
            effect="coverflow"
            [coverflowEffect]="{
                rotate: 0,
            stretch: -120,
            depth: 200,
            slideShadows: false
            }"
            #carousel>
        <ng-template swiperSlide
                     *ngFor="let client of data">
            <a class="slide"
               [routerLink]="'/work/' + client.slug">
                <img class="slide-img"
                     [src]="client.image.url"
                     alt="client">
                <div class="slide-logo-container">
                    <img class="slide-logo"
                         [src]="client.logo.url"
                         alt="logo">
                </div>
                <span class="col-1 slide-cta">View project</span>
            </a>
        </ng-template>
    </swiper>
</section>
