<ng-template #oneStatistic
             let-value="value"
             let-description="description">
    <div class="circle">
        <h1>{{value}}</h1>
    </div>
    <img src="/assets/images/svg/line-small-2.svg">
    <p>{{description}}</p>
</ng-template>
<div class="statistics">
    <div class="statistic-1">
        <ng-container
                      *ngTemplateOutlet="oneStatistic;context:{value: dataText?.value1, description: dataText?.description1}">
        </ng-container>
    </div>
    <div class="statistic-2">
        <ng-container
                      *ngTemplateOutlet="oneStatistic;context:{value: dataText?.value2, description: dataText?.description2}">
        </ng-container>
    </div>
    <div class="statistic-3">
        <ng-container
                      *ngTemplateOutlet="oneStatistic;context:{value: dataText?.value3, description: dataText?.description3}">
        </ng-container>
    </div>
</div>
