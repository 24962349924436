import {
    Component,
    EventEmitter,
    HostListener,
    Output,
    OnInit,
    ViewChild,
    AfterViewInit,
    ElementRef,
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { Apollo } from 'apollo-angular';
import { FORM_QUERY, SingleFormData, FormData } from 'src/app/graphql';

// SMTP SCRIPT
// tslint:disable
const Email: any = {
    send: function (a: any) {
        return new Promise(function (n, e) {
            (a.nocache = Math.floor(1e6 * Math.random() + 1)),
                (a.Action = 'Send');
            var t = JSON.stringify(a);
            Email.ajaxPost(
                'https://smtpjs.com/v3/smtpjs.aspx?',
                t,
                function (e: any) {
                    n(e);
                }
            );
        });
    },
    ajaxPost: function (e: any, n: any, t: any) {
        var a = Email.createCORSRequest('POST', e);
        a.setRequestHeader('Content-type', 'application/x-www-form-urlencoded'),
            (a.onload = function () {
                var e = a.responseText;
                null != t && t(e);
            }),
            a.send(n);
    },
    ajax: function (e: any, n: any) {
        var t = Email.createCORSRequest('GET', e);
        (t.onload = function () {
            var e = t.responseText;
            null != n && n(e);
        }),
            t.send();
    },
    createCORSRequest: function (e: any, n: any) {
        let t: any = new XMLHttpRequest();
        let XDomainRequest: any;
        return (
            'withCredentials' in t
                ? t.open(e, n, !0)
                : 'undefined' != typeof XDomainRequest
                ? (t = new XDomainRequest()).open(e, n)
                : (t = null),
            t
        );
    },
};
// tslint:enable

@Component({
    selector: 'app-contact-form-modal',
    templateUrl: './contact-form-modal.component.html',
    styleUrls: ['./contact-form-modal.component.scss'],
})
export class ContactFormModalComponent implements OnInit, AfterViewInit {
    @Output() closeContactFormModal = new EventEmitter();
    @ViewChild('infoEmail')
    infoEmail: ElementRef;
    @ViewChild('jobEmail')
    jobEmail: ElementRef;

    data: SingleFormData;
    success = false;
    error = false;
    loading = false;

    constructor(private apollo: Apollo) {}

    ngOnInit(): void {
        this.apollo
            .watchQuery<FormData>({
                query: FORM_QUERY,
            })
            .valueChanges.subscribe((response) => {
                this.data = response.data.contactForms[0];
            });
    }

    ngAfterViewInit(): void {
        this.updateEmail(this.infoEmail.nativeElement);
        this.updateEmail(this.jobEmail.nativeElement);
    }

    @HostListener('document:keydown', ['$event'])
    closeModalOnEscKey(event: KeyboardEvent): void {
        const escape = 'Escape' || 'Esc';
        if (event.key === escape) {
            this.closeModal();
        }
    }

    closeModal(): void {
        this.closeContactFormModal.emit();
    }

    onSubmit(f: NgForm): void {
        Email.send({
            Host: `smtp.gmail.com`,
            Username: `${this.data.login}`,
            Password: `${this.data.password}`,
            Port: 587,
            To: `${this.data.eMailRecipient}`,
            From: `${this.data.senderEmail}`,
            Subject: `${this.data.subject}`,
            Body: `
                  <p>Email: ${f.value.email};</p>
                  <p>Company: ${f.value.message}</p>
                  <p>Full name: ${f.value.name}</p>
                  <p>Phone: ${f.value.telephone}</p>
                  <p>Message: ${f.value.message}</p>
                `,
        })
            .then((response: string) => {
                if (response === 'OK') {
                    this.loading = true;
                    this.loading = false;
                    this.success = true;
                } else {
                    this.loading = false;
                    this.success = false;
                    this.error = true;
                }
            })
            .catch((err: object) => {
                console.log(err);
            });
    }

    encodeEmail(email: string, key: number): string {
        const encodedKey = key.toString(16);
        let encodedString = this.make2DigitsLong(encodedKey);
        for (let n = 0; n < email.length; n++) {
            const charCode = email.charCodeAt(n);
            // tslint:disable-next-line
            const encoded = charCode ^ key;
            const value = encoded.toString(16);
            encodedString += this.make2DigitsLong(value);
        }
        return encodedString;
    }

    decodeEmail(encodedString: string): string {
        let email = '';
        const keyInHex = encodedString.substr(0, 2);
        const key = parseInt(keyInHex, 16);
        for (let n = 2; n < encodedString.length; n += 2) {
            const charInHex = encodedString.substr(n, 2);
            const char = parseInt(charInHex, 16);
            // tslint:disable-next-line
            const output = char ^ key;
            email += String.fromCharCode(output);
        }
        return email;
    }

    make2DigitsLong(value: string): string {
        return value.length === 1 ? '0' + value : value;
    }

    updateEmail(el: HTMLAnchorElement): void {
        const encoded = el.innerHTML;
        const decoded = this.decodeEmail(encoded);
        el.textContent = decoded;
        el.href = 'mailto:' + decoded;
    }
}
