import { gql } from 'apollo-angular';

type Components = ImageOnly | ImageText | Statistic | TextOnly;

export interface AwardsSectionData {
    awardsSection: {
        image: {
            url: string;
        };
        title: string;
        copy: string;
        awards: AwardsData[];
    };
}

export interface AwardsData {
    title: string;
    copy: {
        html: InnerHTML;
    };
    year: number;
    image: {
        url: string;
    };
}

export const AWARDS_SECTION_QUERY = gql`
    query awardsSectionQuery {
        awardsSection(where: { id: "ckvkx7y4g16pi0d56i7b6w5p6" }) {
            image {
                url
            }
            title
            copy
            awards {
                title
                copy {
                    html
                }
                year
                image {
                    url
                }
            }
        }
    }
`;

// Home Page
export interface HomePage {
    beforeHighlightText: string;
    highlightText: string;
    afterHighlightText: string;
    banerDescription: string;
    ourWebsitesHighlight: string;
    ourWebsitesAfterHighlight: string;
    descriptionSectionTitle: string;
    descriptionSectionParagraph: string;
}

export interface HomePageData {
    homePages: HomePage[];
}

export const HOME_PAGE_QUERY = gql`
    query MyQuery {
        homePages(first: 1) {
            beforeHighlightText
            highlightText
            afterHighlightText
            banerDescription
            descriptionSectionTitle
            descriptionSectionParagraph
        }
    }
`;

export interface ClientData {
    client: {
        image: {
            url: string;
        };
        logo: {
            url: string;
        };
        slug: string;
        heroBannerTitle: string;
        name: string;
        infoHeading: string;
        infoText: string;
        services: {
            name: string;
            image: {
                url: string;
            };
        }[];
        integrations: {
            logo: {
                url: string;
            };
        }[];
        liveSiteUrl: string;
        components: Components[];
    };
}

export interface ImageOnly {
    __typename: string;
    image: {
        url: string;
    };
}

export interface Statistic {
    __typename: string;
    value1: string;
    description1: string;
    value2: string;
    description2: string;
    value3: string;
    description3: string;
}

export interface TextOnly {
    __typename: string;
    title: string;
    subtitle: string;
}

export interface ImageText {
    __typename: string;
    title: string;
    imageContainerWidth: string;
    image: {
        url: string;
    };
    description: {
        html: InnerHTML;
    };
    imageOnRight: boolean;
}

export const CLIENT_DATA_QUERY = gql`
    query MyQuery($slug: String!) {
        client(where: { slug: $slug }) {
            heroBannerTitle
            image {
                url
            }
            name
            slug
            logo {
                url
            }
            infoHeading
            infoText
            services {
                name
                image {
                    url
                }
            }
            integrations {
                logo {
                    url
                }
            }
            liveSiteUrl
            components {
                __typename
                ... on ImageOnly {
                    image {
                        url
                    }
                }
                ... on Statistic {
                    value1
                    description1
                    value2
                    description2
                    value3
                    description3
                }
                ... on TextOnly {
                    title
                    subtitle
                }
                ... on ImageText {
                    title
                    imageContainerWidth
                    image {
                        url
                    }
                    description {
                        html
                    }
                    imageOnRight
                }
            }
        }
    }
`;

export interface SectionData {
    title: string;
    description: string;
}

export interface AboutSectionData {
    aboutSections: SectionData[];
}

export interface LogosData {
    title: string;
    underlined: string;
    logos: {
        url: string;
    }[];
}

export interface LogosSectionData {
    logosSections: LogosData[];
}

export interface IntroSectionData {
    introSection: {
        paragraphs: ParagraphData[];
    };
}

export interface ParagraphData {
    copy: string;
    fontSize?: number;
    lineHeight?: number;
}

export const INTRO_SECTION_QUERY = gql`
    query introSectionQuery {
        introSection(where: { id: "ckx4kk4ig158h0e51j5yeqxjk" }) {
            paragraphs {
                copy
                fontSize
                lineHeight
            }
        }
    }
`;

export interface PerksSectionData {
    perksSection: {
        title: string;
        perks: PerksData[];
    };
}

export interface PerksData {
    title: string;
    image: {
        url: string;
    };
    copy: string;
}

export const PERKS_SECTION_QUERY = gql`
    query perksSectionQuery {
        perksSection(where: { id: "ckx0fuzjs1yx10d61qzu19w3l" }) {
            title
            perks {
                title
                image {
                    url
                }
                copy
            }
        }
    }
`;

export interface PortfolioSectionData {
    clients: PortfolioData[];
}

export interface PortfolioData {
    slug: string;
    heroBannerTitle: string;
    featured: boolean;
    logo: {
        url: string;
    };
    image: {
        url: string;
    };
}

export const LOGOS_SECTION_QUERY = gql`
    query logosSectionQuery {
        logosSections(first: 1) {
            title
            underlined
            logos {
                url
            }
        }
    }
`;

export interface CarouselClientData {
    image: { url: string };
    logo: { url: string };
    slug: string;
}

export interface CarouselData {
    clients: CarouselClientData[];
}

export const CLIENTS_CAROUSEL_QUERY = gql`
    query CarouselQuery {
        clients {
            image {
                url
            }
            logo {
                url
            }
            slug
        }
    }
`;

export const PORTFOLIO_SECTION_QUERY = gql`
    query PortfolioSectionQuery {
        clients(first: 2) {
            slug
            heroBannerTitle
            featured
            logo {
                url
            }
            image {
                url
            }
        }
    }
`;
// SERVICES
export interface OneServiceData {
    name: string;
    description: string;
    image: {
        url: string;
    };
}

export interface ServiceHeaderData {
    beforeHighlightText: string;
    highlightText: string;
    afterHighlightText: string;
    arrowTitle: string;
}

export interface ServicesData {
    servicesHeaders: ServiceHeaderData[];
    services: OneServiceData[];
}

export const SERVICES_HEADER_QUERY = gql`
    query ServicesHeaderQuery {
        servicesHeaders(first: 1) {
            beforeHighlightText
            highlightText
            afterHighlightText
            arrowTitle
        }
        services(stage: PUBLISHED) {
            name
            description
            image {
                url
            }
        }
    }
`;

export interface WorkPageData {
    workPages: { title: string }[];
}

export const WORK_PAGE_QUERY = gql`
    query WorkPageQuery {
        workPages(first: 1) {
            title
        }
    }
`;

export interface SingleFooterData {
    badgeImage: {
        url: string;
    };
    phoneNumber: string;
    phoneNumberToDisplay: string;
    label: string;
    buttonText: string;
}

export interface FooterData {
    footers: SingleFooterData[];
}

export const FOOTER_QUERY = gql`
    query FooterQuery {
        footers(first: 1) {
            badgeImage {
                url
            }
            phoneNumber
            phoneNumberToDisplay
            label
            buttonText
        }
    }
`;

export interface FormData {
    contactForms: SingleFormData[];
}

export interface SingleFormData {
    eMailRecipient: string;
    errorMessage: string;
    successMessage: string;
    login: string;
    password: string;
    senderEmail: string;
    subject: string;
}

export const FORM_QUERY = gql`
    query FormQuery {
        contactForms(first: 1) {
            eMailRecipient
            errorMessage
            successMessage
            login
            password
            senderEmail
            subject
        }
    }
`;
