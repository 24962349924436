<ng-container *ngIf="data">
    <app-hero [beforeHighlightText]="data.homePages[0].beforeHighlightText"
              [highlightText]="data.homePages[0].highlightText"
              [afterHighlightText]="data.homePages[0].afterHighlightText"
              [banerDescription]="data.homePages[0].banerDescription"></app-hero>
    <app-intro-section></app-intro-section>
    <app-feature-section></app-feature-section>
    <app-logos-section></app-logos-section>
    <app-section [title]="data.homePages[0].descriptionSectionTitle"
                 [description]="data.homePages[0].descriptionSectionParagraph"></app-section>
    <app-portfolio-section></app-portfolio-section>
    <app-awards-section></app-awards-section>
    <app-perks-section></app-perks-section>
</ng-container>