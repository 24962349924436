<div class="feature-section">

    <a [routerLink]="'/work'">
        <h1 class="feature bg-col-4 highlight">
            <span class="content">Explore <br> our
                <span class="comment font-script">our clients!</span>
                <span class="highlight">
                    <app-highlight svgPath="/assets/images/svg/highlight-small.svg">
                        work
                    </app-highlight>
                </span>
                <object class="drawing"
                        data="/assets/images/svg/mouse.svg"></object>
            </span>
        </h1>
    </a>

    <a [routerLink]="'/services'">
        <h1 class="feature bg-col-3 highlight">
            <span class="content">View <br> our
                <span class="comment font-script">ours skills!</span>
                <span class="highlight">
                    <app-highlight svgPath="/assets/images/svg/highlight-small.svg">
                        services
                    </app-highlight>
                </span>
                <object class="drawing"
                        data="/assets/images/svg/bulb.svg"></object>
            </span>
        </h1>
    </a>

</div>
