<div class="hero">
  <h1>
    {{beforeHighlightText}}
    <app-highlight
            class="word font-primary"
            svgPath="/assets/images/svg/highlight.svg">{{highlightText}}</app-highlight>
    <span class="arrow">
      {{afterHighlightText}}
      <span class="arrow_svg">
        <object data="/assets/images/svg/arrow-1.svg"></object>
      </span>
    </span>
  </h1>
  <h3 [innerHTML]="banerDescription"></h3>
</div>
