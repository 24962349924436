import { Component, OnInit } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { LOGOS_SECTION_QUERY, LogosSectionData, LogosData } from 'src/app/graphql';

@Component({
    selector: 'app-logos-section',
    templateUrl: './logos-section.component.html',
    styleUrls: ['./logos-section.component.scss']
})
export class LogosSectionComponent implements OnInit {
    data: LogosData;

    constructor(private apollo: Apollo) {
    }

    ngOnInit(): void {
        this.apollo.watchQuery<LogosSectionData>({
            query: LOGOS_SECTION_QUERY
        }).valueChanges.subscribe((response) => {
            this.data = response.data.logosSections[0];
        });
    }
}
