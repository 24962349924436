<div class="container">
    <app-page-header (openContactFormModal)="handleContactModal(true)"></app-page-header>
    <div class="inner">
        <router-outlet></router-outlet>
    </div>

    <app-contact-form-modal *ngIf="contactModalIsOpen"
                            (closeContactFormModal)="handleContactModal(false)"></app-contact-form-modal>
    <app-page-footer (openContactFormModal)="handleContactModal(true)"></app-page-footer>

</div>
