<div class="section" *ngIf="data">
    <h1 class="font-primary col-b subhero">
        <app-highlight
            class="word font-primary"
            svgPath="/assets/images/svg/highlight.svg">{{data?.underlined}}</app-highlight>
        {{data?.title}}
    </h1>
    <ul *ngIf="data" class="companies-grid">
        <ng-container *ngFor="let logo of data?.logos">
            <li>
                <img src="{{logo.url}}"
                     alt="">
            </li>
        </ng-container>
    </ul>
</div>
