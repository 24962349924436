import { Component, OnInit } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { HOME_PAGE_QUERY, HomePageData} from 'src/app/graphql';

@Component({
  selector: 'app-page-home',
  templateUrl: './page-home.component.html',
  styleUrls: ['./page-home.component.scss']
})
export class PageHomeComponent implements OnInit {
    data: HomePageData;

    constructor(private apollo: Apollo) {}

    ngOnInit(): void {
        this.apollo.watchQuery<HomePageData>({
            query: HOME_PAGE_QUERY
        }).valueChanges.subscribe((response) => {
            this.data = response.data;
        });
    }
}
