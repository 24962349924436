import { Component, OnInit } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { INTRO_SECTION_QUERY, IntroSectionData } from 'src/app/graphql';

@Component({
    selector: 'app-intro-section',
    templateUrl: './intro-section.component.html',
    styleUrls: ['./intro-section.component.scss'],
})
export class IntroSectionComponent implements OnInit {
    data: IntroSectionData;

    constructor(private apollo: Apollo) {}

    ngOnInit(): void {
        this.apollo
            .watchQuery<IntroSectionData>({
                query: INTRO_SECTION_QUERY,
            })
            .valueChanges.subscribe((response) => {
                this.data = response.data;
            });
    }

    pxToRem(val: number): number | undefined {
        if (val) {
            return val / 16;
        }

        return undefined;
    }
}
