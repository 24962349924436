import { Component, OnInit } from '@angular/core';
import { Apollo } from 'apollo-angular';
import {
    PERKS_SECTION_QUERY,
    PerksData,
    PerksSectionData,
} from 'src/app/graphql';

@Component({
    selector: 'app-perks-section',
    templateUrl: './perks-section.component.html',
    styleUrls: ['./perks-section.component.scss'],
})
export class PerksSectionComponent implements OnInit {
    data: PerksSectionData;

    constructor(private apollo: Apollo) {}

    ngOnInit(): void {
        this.apollo
            .watchQuery<PerksSectionData>({
                query: PERKS_SECTION_QUERY,
            })
            .valueChanges.subscribe((response) => {
                this.data = response.data;
            });
    }
}
