import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { FOOTER_QUERY, SingleFooterData, FooterData } from 'src/app/graphql';

@Component({
    selector: 'app-page-footer',
    templateUrl: './page-footer.component.html',
    styleUrls: ['./page-footer.component.scss']
})
export class PageFooterComponent implements OnInit {
    data: SingleFooterData;
    @Output() openContactFormModal = new EventEmitter();

    constructor(private apollo: Apollo) {
    }

    ngOnInit(): void {
        this.apollo.watchQuery<FooterData>({
            query: FOOTER_QUERY
        }).valueChanges.subscribe((response) => {
            this.data = response.data.footers[0];
        });
    }
    openFormModal(): void {
        this.openContactFormModal.emit();
    }
}
