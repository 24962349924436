import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { AWARDS_SECTION_QUERY, AwardsSectionData } from 'src/app/graphql';

@Component({
    selector: 'app-awards-section',
    templateUrl: './awards-section.component.html',
    styleUrls: ['./awards-section.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class AwardsSectionComponent implements OnInit {
    data: AwardsSectionData;

    isMobileTablet: boolean;

    constructor(private apollo: Apollo) {}

    ngOnInit(): void {
        this.apollo
            .watchQuery<AwardsSectionData>({
                query: AWARDS_SECTION_QUERY,
            })
            .valueChanges.subscribe((response) => {
                this.data = response.data;
            });

        this.onWindowResize();
    }

    private onWindowResize(): void {
        this.isMobileTablet = window.innerWidth < 991;
    }
}
