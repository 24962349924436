import { Component, OnInit } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { WORK_PAGE_QUERY, WorkPageData } from 'src/app/graphql';

@Component({
  selector: 'app-page-work',
  templateUrl: './page-work.component.html',
  styleUrls: ['./page-work.component.scss'],
})
export class PageWorkComponent implements OnInit {
  title: string;

  constructor(private apollo: Apollo) {}

  ngOnInit(): void {
    this.apollo
      .watchQuery<WorkPageData>({
        query: WORK_PAGE_QUERY,
      })
      .valueChanges.subscribe((response) => {
        this.title = response.data.workPages[0].title;
      });
  }
}
